.singleBlog {
    height: 420px;
    width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    border-radius: 5px;
}

.singleBlog--image {
    height: 60%;
    width: 100%;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.singleBlog--image > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: transform 1s, filter 2s ease-in-out;
    filter: blur(1px);
    transform: scale(1.3);
}

.singleBlog:hover > .singleBlog--image > img {
    filter: grayscale(0);
    transform: scale(1.1);
}

.singleBlog--body {
    padding: 1rem;
}

.singleBlog--body p {

    /* font-weight: 600; */
    /* font-size: 13px; */
    margin-bottom: 0.65rem;
}

.singleBlog--body h3 {

    margin-bottom: 0.55rem;
    font-size: 19px;
    line-height: 115%;
}

.singleBlog--body h6 {
 
    font-size: 15px;
    line-height: 139%;
    font-weight: 500;
    opacity: 0.9;
}



@media (min-width: 992px) and (max-width: 1380px) {
    .singleBlog {
        height: 430px;
    }
}


@media screen and (max-width: 992px) {
    .singleBlog {
        height: 450px;
        background-color: rgb(171, 240, 240);
        /* background-color: rgb(175, 182, 182); */
        margin: 2rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: baseline;
    }
}

@media screen and (max-width: 800px) {
    .singleBlog {
        height: 450px;
        margin: 1rem auto;
    }
}

@media screen and (max-width: 700px) {
    .singleBlog {
        height: 450px;
        margin: 1rem auto;
    }
}

@media screen and (max-width: 600px) {
    .singleBlog {
        height: 450px;
        width: 300px;
        margin: 1rem 0;
    }
    .singleBlog--image > img {
        filter: blur(0);
    }
}

@media screen and (max-width: 320px) {
    .singleBlog {
        transform: scale(0.9);
    }

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}


@media only screen and (device-width: 768px) {

}